<template>
  <div>
    <div class="card">
      <div class="card-body">

        <div class="form-group row">
          <label class="col-sm-2 col-form-label" for="groups">
            Grupa
          </label>
          <div class="col-sm-10">
            <multiselect v-model="selectedGroup"
                         id="groups"
                         track-by="id"
                         label="name"
                         :close-on-select="false"
                         :multiple="true"
                         :placeholder="'Wybierz'"
                         :selectLabel="'Naciśnij enter aby wybrać'"
                         :deselectLabel="'Naciśnij enter aby usunąć'"
                         :options="groups"></multiselect>
            <input type="hidden" name="groups[]" v-for="item in selectedGroup" v-bind:value="item.id"/>
          </div>
        </div>

      </div>
    </div>
    LUB
    <div class="card mt-3">
      <div class="card-body">

        <div class="form-group row">
          <label class="col-sm-2 col-form-label" for="users">
            Użytkownik
          </label>
          <div class="col-sm-10">
            <multiselect v-model="selectedUser"
                         id="users"
                         track-by="id"
                         :searchable="true"
                         label="name"
                         :loading="isLoading"
                         :internal-search="false"
                         :clear-on-select="false"
                         :close-on-select="false"
                         :options-limit="300"
                         :limit="3"
                         @search-change="asyncFind"
                         :multiple="true"
                         :placeholder="'Wybierz'"
                         :selectLabel="'Naciśnij enter aby wybrać'"
                         :deselectLabel="'Naciśnij enter aby usunąć'"
                         :options="users"></multiselect>
            <input type="hidden" name="users[]" v-for="item in selectedUser" v-bind:value="item.id"/>
          </div>
        </div>

      </div>
    </div>

    <div v-for="selectedApp in selectedApps">
      LUB
      <div class="card mt-3">
        <div class="card-body">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="apps">
              Aplikacja
            </label>
            <div class="col-sm-10">
              <multiselect v-model="selectedApp.application"
                           id="apps"
                           track-by="id"
                           label="name"
                           :placeholder="'Wybierz'"
                           :selectLabel="'Naciśnij enter aby wybrać'"
                           :deselectLabel="'Naciśnij enter aby usunąć'"
                           :options="applications"></multiselect>
              <input type="hidden" name="application[]"
                     v-bind:value="selectedApp.application?selectedApp.application.id:null"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="cities">
              Miasto
            </label>
            <div class="col-sm-10">
              <multiselect v-model="selectedApp.cities"
                           id="cities"
                           :close-on-select="false"
                           :multiple="true"
                           v-bind:disabled="!selectedApp.application"
                           :placeholder="'Wybierz'"
                           :selectLabel="'Naciśnij enter aby wybrać'"
                           :deselectLabel="'Naciśnij enter aby usunąć'"
                           :options="getCities(selectedApp.application)"></multiselect>

              <input type="hidden" :name="'cities['+ selectedApp.application.id +'][]'"
                     v-for="item in selectedApp.cities" v-bind:value="item"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <button type="button" class="btn mb-3 btn-outline-primary" v-on:click="addApp()">Dodaj aplikację</button>
  </div>
</template>
<script>
import * as _ from 'lodash';
import Multiselect from 'vue-multiselect'

export default {
  name: 'AppliactionCity',
  components: {Multiselect},
  methods: {
    getJoinedCities(cities) {
      if (_.isNil(cities)) {
        return '';

      }
      return cities.join(',');
    },
    getApp: function (id) {
      return _.find(this.applications, app => app.id === id);
    },
    getCities: function (application) {
      if (!application) {
        return [];
      }
      return application.cities.split('\r\n');
    },
    asyncFind(query) {
      this.isLoading = true
      axios
          .get((window.listUrl ? window.listUrl : '/admin/user/list') + '?search=' + query)
          .then(response => {
            this.users = response.data.results;
            // console.log(response);
            this.isLoading = false;
          });

    },
    addApp() {
      this.selectedApps.push({
        application: null,
        cities: null
      });
    }
  },
  data() {
    return {
      error: '',
      selectedGroup: null,
      selectedApps: [],
      selectedCity: null,
      selectedUser: null,
      isLoading: false,
      users: []
    };
  },
  props: {
    applications: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: true
    }
  },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
